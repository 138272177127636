"use client";

import {useMutation} from "@tanstack/react-query";
import {Button, Divider, Drawer, Form, Typography, message} from "antd";
import {useForm} from "antd/es/form/Form";
import React, {useEffect, useState} from "react";
import {createUseStyles} from "react-jss";

import {UserModule} from "@/modules/user";
import {useAppStore} from "@/stores/app-store";

import {FormBuilder, FormMeta, FormMetaField} from "./form-builder";

const useStyles = createUseStyles({
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  profileRow: {
    display: "grid",
    gridTemplateColumns: "1fr 2fr",
  },
  profileInfo: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
});

interface ProfileDrawerProps {
  profileDrawerOpen: boolean;
  handleProfileDrawerToggle: () => void;
}

const ProfileDrawer = ({profileDrawerOpen, handleProfileDrawerToggle}: ProfileDrawerProps) => {
  const styles = useStyles();
  const [editPassword, setEditPassword] = useState<boolean>(false);

  const [fetchMe, me] = useAppStore((state) => [state.fetchMe, state.me]);
  const [form] = useForm();

  useEffect(() => {
    if (!me) fetchMe();
  }, [fetchMe, me]);

  //FORM DEFINITION

  const formKeys = {
    oldPassword: "oldPassword",
    newPassword: "newPassword",
    confirmPassword: "confirmPassword",
  };

  const passwordValidator = async (_: unknown, value: string) => {
    const confirmPassword = form.getFieldValue(formKeys.confirmPassword);
    if (value && confirmPassword && value !== confirmPassword) {
      throw new Error("Passwords do not match");
    }
    if (value == form.getFieldValue(formKeys.oldPassword)) {
      throw new Error("new password must be different than old one");
    }
  };

  const fields: FormMetaField[] = [
    {
      key: formKeys.oldPassword,
      name: formKeys.oldPassword,
      label: "Old password",
      widget: "password",
      colon: false,
      rules: [{required: true, message: "Please enter your password"}],
    },
    {
      key: formKeys.newPassword,
      name: formKeys.newPassword,
      label: "New password",
      widget: "password",
      rules: [{required: true, message: "Please enter your password"}, {validator: passwordValidator}],
      colon: false,
    },
    {
      key: formKeys.confirmPassword,
      name: formKeys.confirmPassword,
      label: "Confirm password",
      widget: "password",
      rules: [{required: true, message: "Please enter your password"}],
      colon: false,
    },
  ];

  const finalFormMeta: FormMeta = {
    viewMode: false,
    columns: 1,
    fields: [...fields],
  };

  // FORM DEFINITION END

  const updatePasswordQuery = useMutation({mutationFn: UserModule.updatePassword});

  const onClose = () => {
    setEditPassword(false);
    form.resetFields();
    handleProfileDrawerToggle();
  };

  const onCancel = () => {
    setEditPassword(false);
    form.resetFields();
  };

  const onSubmit = async () => {
    const [oldPassword, newPassword] = [
      form.getFieldValue(formKeys.oldPassword),
      form.getFieldValue(formKeys.newPassword),
    ];

    try {
      await updatePasswordQuery.mutateAsync({oldPassword, newPassword});
      message.success("Password changed successfully");
      setEditPassword(false);
      form.resetFields();
    } catch (error) {
      message.error("Failed to update, please check your password. and try again");
    }
  };

  const renderChangePasswordPanel = () => (
    <Form onFinish={() => onSubmit()} form={form}>
      <FormBuilder form={form} meta={finalFormMeta} />
      <section className={styles.buttons}>
        <Button disabled={updatePasswordQuery.isPending} onClick={onCancel}>
          Cancel
        </Button>
        <Button loading={updatePasswordQuery.isPending} type="primary" htmlType="submit">
          Change password
        </Button>
      </section>
    </Form>
  );

  const renderProfileInfoPanel = () => (
    <section className={styles.profileInfo}>
      <Typography.Text className={styles.profileRow}>
        <b>Name:</b> <span>{me?.nickName || me?.firstName}</span>
      </Typography.Text>
      <Typography.Text className={styles.profileRow}>
        <b>Email:</b> <span>{me?.email}</span>
      </Typography.Text>
      <Typography.Text className={styles.profileRow}>
        <b>Role:</b> <span>{me?.role}</span>
      </Typography.Text>
      <Divider />
      <Button onClick={() => setEditPassword(true)}>Change password</Button>
      <Button onClick={() => message.info("TODO: feature not implemented yet")}>Reset password</Button>
    </section>
  );

  /* 
  TODO: check if credentials sign in is enabled in the project, if not, remove the 
  password related code of this component, icluding: state, title change, change password 
  button below and renderPasswordChangePanel 
  */

  return (
    <Drawer
      destroyOnClose
      onClose={onClose}
      open={profileDrawerOpen}
      title={editPassword ? "Change password" : "User Profile"}>
      {editPassword ? renderChangePasswordPanel() : renderProfileInfoPanel()}
    </Drawer>
  );
};

export default ProfileDrawer;
