// https://github.com/you-dont-need/You-Dont-Need-Lodash-Underscore#_throttle
export const throttle = (func, timeFrame) => {
  let lastTime = 0;
  return function (...args) {
    const now = new Date();
    if (now - lastTime >= timeFrame) {
      func(...args);
      lastTime = now;
    }
  };
};
