"use client";

import {HomeOutlined, UserOutlined} from "@ant-design/icons";
import {Avatar, Dropdown, Layout as AntdLayout, Menu, Space, Typography} from "antd";
import {NextPage} from "next";
import Link from "next/link";
import {usePathname, useRouter} from "next/navigation";
import {signOut, useSession} from "next-auth/react";
import React, {ReactNode, useEffect, useMemo, useState} from "react";
import {createUseStyles, useTheme} from "react-jss";

import {MenuClickEventHandler} from "../../../typings/antd";
import AboutModal from "@/app/_components/about-modal";
import SignInForm from "@/app/_components/sign-in-form";
import {InfoIcon} from "@/assets/icons/Info";
import {PowerIcon} from "@/assets/icons/Power";
import useBreakpoint from "@/hooks/useBreakpoint";
import {useThemeActions} from "@/hooks/useTheme";
import {LocalPublicConfigModule} from "@/modules/public-config";
import {PublicConfigModule} from "@/shared/modules/public-config";
import {GravatarUtils} from "@/shared/utils/gravatar";

import ProfileDrawer from "./profile-drawer";

const SIDER_COLLAPSED_KEY = "ui.preferences.sider.collapsed";

const useStyles = createUseStyles({
  "@global": {
    ".ant-menu-item": {
      color: "grey !important",
      "&:hover": {
        backgroundColor: "#D3D3D3 !important",
      },
    },
    ".ant-menu-item-selected": {
      backgroundColor: "#3057D1 !important",
      color: "white !important",
      "&:hover": {
        backgroundColor: "#3057D1 !important",
      },
    },
    ".ant-menu": {
      borderRight: "1px solid #D6E4FF !important",
    },
  },
  layout: {
    height: "100vh",
    overflow: "hidden",
  },
  headerContainer: {
    display: "flex",
    height: 48,
    justifyContent: "space-between",
  },
  headerTitle: {
    marginBottom: [0, "!important"],
    cursor: "pointer",
    color: ["#ffffffd9", "!important"],
    fontWeight: "400 !important",
  },
  userContainer: {
    cursor: "pointer",
  },
  userDataContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  userDataText: {
    color: ["#ffffffd9", "!important"],
  },
  userAvatar: {
    borderColor: "lightgray",
    borderWidth: 2,
    borderStyle: "solid",
  },
  userMenuIcon: {
    height: 20,
    width: 20,
  },
  sider: {
    height: "100vh",
  },
  menu: {
    height: "100vh",
    background: "#EFF2F7 !important",
  },
  contentContainer: {
    overflowY: "scroll",
    overflowX: "scroll",
  },
});

const InnerLayout: NextPage<{children: ReactNode}> = ({children}) => {
  const styles = useStyles();
  const theme = useTheme();
  const {toggleMode: toggleThemeMode} = useThemeActions();
  const router = useRouter();
  const pathname = usePathname();
  const {data: session, status: sessionStatus} = useSession();
  const user = session?.user;
  const {lg} = useBreakpoint();

  useEffect(() => {
    PublicConfigModule.getPublicConfigs().then(LocalPublicConfigModule.setPublicConfigs);
  }, []);

  const defaultSiderCollapsed = window.localStorage.getItem(SIDER_COLLAPSED_KEY) === "true";
  const [siderCollapsed, setSiderCollapsed] = useState(defaultSiderCollapsed);

  const [aboutOpen, setAboutOpen] = useState(false);
  const [profileDrawerOpen, setProfileDrawerOpen] = useState<boolean>(false);

  const avatarUrl = useMemo(() => {
    if (!user?.email) return "";

    const avatarSearchParams = new URLSearchParams({
      size: "40",
      default: `https://www.gravatar.com/avatar/${GravatarUtils.getDefaultEmailHash()}?size=40`,
    });

    const emailHash = GravatarUtils.getEmailHash(user.email);
    return `https://www.gravatar.com/avatar/${emailHash}?${avatarSearchParams.toString()}`;
  }, [user]);

  const handleUserMenuClick: MenuClickEventHandler = ({key}) => {
    switch (key) {
      case "toggleThemeMode":
        setTimeout(() => toggleThemeMode(), 400);
        break;
      case "about":
        setAboutOpen(true);
        break;
      case "profile":
        setProfileDrawerOpen(true);
        break;
      case "signOut":
        signOut();
        break;
      default:
        break;
    }
  };

  const handleSiderCollapse = (value: boolean) => {
    window.localStorage.setItem(SIDER_COLLAPSED_KEY, value.toString());
    setSiderCollapsed(value);
  };

  const handleTitleClick = () => {
    router.push("/");
  };

  const handleAboutOpenToggle = () => {
    setAboutOpen(!aboutOpen);
  };

  const handleProfileDrawerToggle = () => {
    setProfileDrawerOpen(!profileDrawerOpen);
  };

  const items = useMemo(() => {
    if (!session) {
      return [];
    }
    const menu = [{key: "/", icon: <HomeOutlined />, label: "Home"}];
    if (session.roles?.isAdministrator) {
      return [...menu, {key: "/admin/users", icon: <UserOutlined />, label: "Users"}];
    }
    return menu;
  }, [session]);

  return (
    <AntdLayout className={styles.layout}>
      <AntdLayout.Header style={{background: "#3057D1"}}>
        <Space className={styles.headerContainer}>
          <Space>
            <Typography.Title className={styles.headerTitle} level={lg ? 3 : 5} onClick={handleTitleClick}>
              Forrester - Backoffice
            </Typography.Title>
          </Space>
          {session && (
            <Space>
              <Dropdown
                placement="bottomRight"
                menu={{
                  items: [
                    {
                      key: "about",
                      icon: <InfoIcon className={styles.userMenuIcon} />,
                      label: "About",
                    },
                    {
                      key: "profile",
                      icon: <UserOutlined className={styles.userMenuIcon} />,
                      label: "Profile",
                    },
                    {type: "divider"},
                    {
                      key: "signOut",
                      icon: <PowerIcon className={styles.userMenuIcon} />,
                      label: "Sign out",
                    },
                  ],
                  style: {width: "max-content"},
                  onClick: handleUserMenuClick,
                }}
                trigger={["click"]}>
                <Space className={styles.userContainer}>
                  <div className={styles.userDataContainer}>
                    <Typography.Text className={styles.userDataText} strong>
                      {session.user.name}
                    </Typography.Text>
                    <Typography.Text className={styles.userDataText}>
                      {session.user.email?.split("@")[0]}
                    </Typography.Text>
                  </div>
                  <Avatar className={styles.userAvatar} size={38} src={avatarUrl} alt={session.user.name} />
                </Space>
              </Dropdown>
            </Space>
          )}
        </Space>
        <AboutModal open={aboutOpen} onCancel={handleAboutOpenToggle}></AboutModal>
      </AntdLayout.Header>
      <AntdLayout hasSider>
        {session && (
          <AntdLayout.Sider
            className={styles.sider}
            collapsible
            defaultCollapsed={defaultSiderCollapsed}
            collapsed={siderCollapsed}
            onCollapse={handleSiderCollapse}>
            <Menu
              className={styles.menu}
              mode="inline"
              theme={theme.isLight ? "dark" : undefined}
              items={items}
              defaultSelectedKeys={["/"]}
              selectedKeys={pathname ? [pathname] : []}
              _internalRenderMenuItem={(originNode, menuItemProps) => (
                <Link href={menuItemProps.eventKey} legacyBehavior>
                  {originNode}
                </Link>
              )}
            />
          </AntdLayout.Sider>
        )}

        <AntdLayout>
          <AntdLayout.Content className={styles.contentContainer}>
            {sessionStatus === "unauthenticated" && !session && <SignInForm />}
            {session && children}
          </AntdLayout.Content>
        </AntdLayout>
      </AntdLayout>
      <ProfileDrawer profileDrawerOpen={profileDrawerOpen} handleProfileDrawerToggle={handleProfileDrawerToggle} />
    </AntdLayout>
  );
};

export default InnerLayout;
