import type {ThemeConfig} from "antd";

import baseThemeConfig from "@/styles/themes/base-theme-config";

// https://ant.design/docs/react/customize-theme#seedtoken
const themeConfig: ThemeConfig = {
  ...baseThemeConfig,
  token: {
    ...baseThemeConfig.token,
    colorPrimary: "#ed7203",
  },
};

export default themeConfig;
