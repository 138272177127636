import type {ThemeConfig} from "antd";

const baseThemeConfig: ThemeConfig = {
  components: {
    Layout: {
      headerBg: "#1f1f1f",
      triggerBg: "",
    },
    Table: {
      headerSortActiveBg: "",
      bodySortBg: "",
    },
  },
};

export default baseThemeConfig;
