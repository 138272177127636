import {useState, useEffect} from "react";

import {throttle} from "@/utils/throttle";

type Breakpoint = "xxl" | "xl" | "lg" | "md" | "sm" | "xs";
type ScreenMap = Partial<Record<Breakpoint, boolean>>;

const getDeviceConfig = (width: number): ScreenMap => {
  if (width < 320) {
    return {xs: true};
  } else if (width >= 320 && width < 720) {
    return {sm: true};
  } else if (width >= 720 && width < 1024) {
    return {md: true};
  } else if (width >= 1024) {
    return {lg: true};
  }

  return {};
};

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() => getDeviceConfig(window.innerWidth));

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(getDeviceConfig(window.innerWidth));
    }, 200);

    window.addEventListener("resize", calcInnerWidth);
    return () => window.removeEventListener("resize", calcInnerWidth);
  }, []);

  return brkPnt;
};

export default useBreakpoint;
