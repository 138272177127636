import {create} from "zustand";

import {authorizedClientRequest} from "@/api/csr-request";
import {User, MeDocument, MeQuery} from "@/shared/codegen/types";

export type AppStoreI = {
  me: User | undefined;

  fetchMe: () => Promise<MeQuery>;
};

export const useAppStore = create<AppStoreI>()((set) => ({
  me: undefined,

  fetchMe: async () => {
    return authorizedClientRequest({
      document: MeDocument,
      variables: {},
    }).then((data) => {
      if (data.me) set({me: data.me});
      return data;
    });
  },
}));
