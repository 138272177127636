"use client";

import {StyleProvider as Provider, createCache, extractStyle} from "@ant-design/cssinjs";
import {useServerInsertedHTML} from "next/navigation";
import React from "react";

const cache = createCache();

// Using Next.js App Router
// https://ant.design/docs/react/use-with-next#using-nextjs-app-router
const StyleProvider = ({children}: {children: React.ReactNode}) => {
  useServerInsertedHTML(() => <style id="antd" dangerouslySetInnerHTML={{__html: extractStyle(cache, true)}} />);
  return <Provider cache={cache}>{children}</Provider>;
};

export default StyleProvider;
