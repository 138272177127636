"use client";

import {ErrorBoundary} from "@stefanprobst/next-error-boundary";
import {QueryClient, QueryClientProvider} from "@tanstack/react-query";
import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {Analytics} from "@vercel/analytics/react";
import {usePathname} from "next/navigation";
import {SessionProvider} from "next-auth/react";
import React from "react";

import ErrorResult from "@/app/_components/error-result";
import InnerLayout from "@/app/_components/inner-layout";

import ThemeProvider from "./theme-provider";
const queryClient = new QueryClient();

const AppWrapper = ({children}: React.PropsWithChildren) => {
  const pathname = usePathname();
  return (
    <ThemeProvider>
      <SessionProvider>
        <QueryClientProvider client={queryClient}>
          <InnerLayout>
            <ErrorBoundary
              key={pathname}
              fallback={<ErrorResult />}
              onError={(error, errorInfo) => console.error(error, errorInfo)}>
              {children}
              <Analytics />
            </ErrorBoundary>
          </InnerLayout>

          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SessionProvider>
    </ThemeProvider>
  );
};

export default AppWrapper;
