"use client";

import {theme} from "antd";
import React from "react";
import {ThemeProvider as Provider} from "react-jss";

import {useThemeMode} from "@/hooks/useTheme";
import {darkTheme, lightTheme} from "@/styles/themes";

const ThemeProvider = ({children}: React.PropsWithChildren) => {
  const {token} = theme.useToken();
  const themeMode = useThemeMode();

  return (
    <Provider
      theme={{
        ...(themeMode === "light" ? lightTheme : darkTheme),
        token: token,
      }}>
      {children}
    </Provider>
  );
};

export default ThemeProvider;
