"use client";

import {Button, Input, Typography} from "antd";
import Image from "next/image";
import {signIn} from "next-auth/react";
import React, {MouseEvent, useState} from "react";
import {createUseStyles} from "react-jss";

import Logo from "../../assets/logo.png";
import {AuthenticationProvider} from "@/shared/modules/authentication/authentication-provider";

const width = "300px";

const useStyles = createUseStyles({
  container: {
    height: "95vh",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    backgroundColor: "#FEFCFA",
  },
  loginContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width,
  },
  input: {
    margin: 5,
    "&:hover": {
      borderColor: "#D0D8E3 !important",
      boxShadow: "0 0 0 2px rgba(0, 123, 255, 0.1) !important",
      outline: "0 !important",
      backgroundColor: "#F2F5FF !important",
    },
    "&.ant-select-selector:hover": {
      borderColor: "#D0D8E3 !important",
      boxShadow: "0 0 0 2px rgba(0, 123, 255, 0.1) !important",
      outline: "0 !important",
      backgroundColor: "#F2F5FF !important",
    },
    "&:focus": {
      borderColor: "#007BFF !important",
      boxShadow: "0 0 0 2px rgba(0, 123, 255, 0.1) !important",
      outline: "0 !important",
      backgroundColor: "#F2F5FF !important",
    },
    "&:focus-within": {
      borderColor: "#007BFF !important",
      boxShadow: "0 0 0 2px rgba(0, 123, 255, 0.1) !important",
      outline: "0 !important",
      backgroundColor: "#F2F5FF !important",
    },
  },
  button: {
    margin: "5px 0",
    alignItems: "center",
    width,
    backgroundColor: "#3057D1",
    "&:hover": {
      backgroundColor: "#3057D1 !important",
    },
  },
  buttonIcon: {
    height: 20,
    width: 20,
  },
  buttonText: {
    marginLeft: 10,
  },
  text: {
    marginTop: "1.5rem",
    marginBottom: "0.5rem",
  },
  title: {
    color: "#3057D1",
    fontSize: "60px",
    fontWeight: "500",
  },
  logo: {
    width: "75%",
    objectFit: "contain",
    height: "auto",
  },
});

const SignInForm = () => {
  const styles = useStyles();

  const [email, setEmail] = useState<string | undefined>();
  const [password, setPassword] = useState<string | undefined>();

  const handleCredentialsSignIn = (e: MouseEvent) => {
    e.preventDefault();
    signIn(AuthenticationProvider.Credentials, {email, password});
  };

  return (
    <div className={styles.container}>
      <div className={styles.loginContainer}>
        <Image className={styles.logo} src={Logo} alt="company logo" />
        <Typography.Text className={styles.text}>Sign in to continue:</Typography.Text>
        <Input
          className={styles.input}
          type="email"
          value={email}
          onChange={(event) => setEmail(event.target.value)}
          placeholder="email@example.com"
          style={{backgroundColor: "#F2F5FF"}}
        />
        <Input
          className={styles.input}
          type="password"
          value={password}
          onChange={(event) => setPassword(event.target.value)}
          placeholder="Write your password"
          style={{backgroundColor: "#F2F5FF"}}
        />
        <Button className={styles.button} type="primary" size="large" onClick={handleCredentialsSignIn}>
          Sign in
        </Button>
      </div>
    </div>
  );
};

export default SignInForm;
