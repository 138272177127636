import {authorizedClientRequest} from "@/api/csr-request";
import {
  CreatePublicConfigMutationVariables,
  PublicConfig,
  CreatePublicConfigDocument,
  UpdatePublicConfigMutationVariables,
  UpdatePublicConfigDocument,
  DeletePublicConfigDocument,
  PublicConfigsDocument,
  PublicConfigsQueryVariables,
} from "@/shared/codegen/types";

export const PUBLIC_CONFIG_PREFIX = "public.config.";

type PublicConfigValue = boolean | string | number;

function getPublicConfig(key: string): PublicConfigValue | null {
  const value = window.localStorage.getItem(PUBLIC_CONFIG_PREFIX + key);
  if (!value || value.length === 0) return null;
  if (value.toLowerCase() === "true") return true;
  if (value.toLowerCase() === "false") return false;
  const number = Number(value);
  if (!isNaN(number)) return number;
  return value;
}

function setPublicConfig(key: string, value: PublicConfigValue) {
  window.localStorage.setItem(PUBLIC_CONFIG_PREFIX + key, value.toString());
}

function setPublicConfigs(configs: Record<string, PublicConfigValue>) {
  Object.entries(configs).forEach(([id, value]) => setPublicConfig(id, value));
}

export const LocalPublicConfigModule = {
  getPublicConfig,
  setPublicConfig,
  setPublicConfigs,
};

async function fetchPublicConfigs(variables: PublicConfigsQueryVariables) {
  const result = await authorizedClientRequest({
    document: PublicConfigsDocument,
    variables: {
      ...variables,
    },
  });
  if (result.publicConfigs) return result.publicConfigs;
  throw new Error("Couldn't retrieve public configs.");
}

async function createPublicConfig(request: CreatePublicConfigMutationVariables): Promise<PublicConfig> {
  const response = await authorizedClientRequest({
    document: CreatePublicConfigDocument,
    variables: request,
  });

  if (response.createPublicConfig) return response.createPublicConfig;
  throw new Error("couldn't create public config");
}

async function updatePublicConfig(request: UpdatePublicConfigMutationVariables): Promise<PublicConfig> {
  const {id, input} = request;
  const response = await authorizedClientRequest({
    document: UpdatePublicConfigDocument,
    variables: {id: id, input: {value: input.value}},
  });

  if (response.updatePublicConfig) return response.updatePublicConfig;
  throw new Error("couldn't update public config");
}

async function deletePublicConfig(id: string): Promise<boolean> {
  const response = await authorizedClientRequest({
    document: DeletePublicConfigDocument,
    variables: {id},
  });

  if (response.deletePublicConfig) return response.deletePublicConfig;
  throw new Error("couldn't delete public config");
}

export const PublicConfigModule = {
  fetchPublicConfigs,
  createPublicConfig,
  updatePublicConfig,
  deletePublicConfig,
};
