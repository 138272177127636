import {PublicConfigsDocument, PublicConfigsQueryVariables} from "@/shared/codegen/types";
import {serverRequest} from "@/shared/request";

async function fetchPublicConfigs(variables: PublicConfigsQueryVariables) {
  const result = await serverRequest({
    document: PublicConfigsDocument,
    variables: {
      ...variables,
    },
  });
  if (result.publicConfigs) return result.publicConfigs;
  throw new Error("Couldn't retrieve public configs.");
}

async function getPublicConfigs(): Promise<Record<string, string>> {
  const result = await fetchPublicConfigs({first: 100});
  const configs = result.edges.reduce(
    (result, {node: {id, value}}) => ({...result, [id]: value}),
    {} as Record<string, string>
  );
  return configs;
}

export const PublicConfigModule = {
  getPublicConfigs,
};
