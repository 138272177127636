import md5 from "md5";

let defaultEmailHash = "";

const getDefaultEmailHash = () => {
  if (!defaultEmailHash) defaultEmailHash = getEmailHash("gravatar@orangeloops.com");
  return defaultEmailHash;
};

const getEmailHash = (email: string) => md5(email.trim().toLowerCase());

export const GravatarUtils = {
  getDefaultEmailHash,
  getEmailHash,
};
