"use client";

import {App, ConfigProvider as Provider, theme, ThemeConfig} from "antd";
import React, {useEffect, useMemo} from "react";

import {useThemeActions, useThemeMode} from "@/hooks/useTheme";
import darkThemeConfig from "@/styles/themes/dark-theme-config";
import lightThemeConfig from "@/styles/themes/light-theme-config";

const ConfigProvider = ({children}: React.PropsWithChildren) => {
  const themeMode = useThemeMode();
  const {load: loadTheme} = useThemeActions();

  useEffect(() => {
    loadTheme();
  }, [loadTheme]);

  const themeConfig = useMemo<ThemeConfig>(
    () =>
      themeMode === "light"
        ? {
            ...lightThemeConfig,
            algorithm: [theme.defaultAlgorithm],
          }
        : {
            ...darkThemeConfig,
            algorithm: [theme.darkAlgorithm],
          },
    [themeMode]
  );

  if (!themeMode) return null;

  return (
    <Provider theme={themeConfig}>
      <App>{children}</App>
    </Provider>
  );
};

export default ConfigProvider;
